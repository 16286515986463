import React from "react"
import "../styles/styles.scss"
import "../styles/bootstrap.scss"
import { Link } from "gatsby"
import img from "../images/girl.svg"

const NotFound = () => {
  return (
    <section className="notFound">
      <img src={img} alt="girl" style={{ height: "18em" }} />
      <div>
        <h3>Page Not Found</h3>
        <Link to="/"> click here to go back</Link>
      </div>
    </section>
  )
}

export default NotFound
